/* eslint-disable jsx-a11y/anchor-is-valid */
import styled from "styled-components";
import { useState } from "react";
import { useLocation } from "react-router-dom";
const Navbar = styled.nav`
  background: ${(props) => (props.menuIsOpen ? "#fff !important" : "none")};

  .navbar-item {
    font-size: 12px;
    font-weight: 500;
  }

  .navbar-item.navbar-item-container {
    display: grid;
  }

  .navbar-item img {
    margin: 90px 0 0 30px;
    width: 220px;
    max-height: 220px !important;
  }

  .navbar-brand .navbar-item {
    margin-bottom: 5px;
    p {
      color: #000;
      margin: 30px 0 0 30px;
    }
  }

  .navbar-burger:hover {
    background: none;
  }

  .navbar-menu a.navbar-item:hover {
    border-bottom: 1px solid
      ${(props) => (props.headerVisible ? "#000" : "#000")};
    background: none;
    color: ${(props) => (props.headerVisible ? "#000" : "#000")};
  }
  .navbar-link.is-active,
  .navbar-link:focus,
  .navbar-link:focus-within,
  .navbar-link:hover,
  a.navbar-item.is-active,
  a.navbar-item:focus,
  a.navbar-item:focus-within,
  a.navbar-item:hover {
    background: none;
    outline: none;
    color: ${(props) =>
      props.menuIsOpen ? "#000" : props.headerVisible ? "#000" : "#000"};
  }
  .navbar-item,
  .navbar-link {
    color: ${(props) =>
      props.menuIsOpen ? "#000" : props.headerVisible ? "#000" : "#000"};
  }
  .navbar-burger {
    color: ${(props) =>
      props.menuIsOpen ? "#4a4a4a" : props.headerVisible ? "#000" : "#000"};
  }
  @media (max-width: 768px) {
    background: ${(props) =>
      props.isHome ? (props.headerVisible ? "none" : "#fff") : "#fff"};
  }
  @media screen and (min-width: 1024px) {
    .navbar-item,
    .navbar-link {
      align-items: flex-start;
    }
  }

  @media screen and (max-width: 768px) {
    .navbar-item img {
      width: 120px;
      margin: 10px 0 0 10px;
      max-height: 120px !important;
    }
  }
`;
function Header({ headerVisible }) {
  const location = useLocation();
  const isHome = location.pathname === "/" ? true : false;
  const shouldHeaderBeVisible = isHome ? headerVisible : false;
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const isActiveClassName = menuIsOpen ? "is-active" : "";
  return (
    <Navbar
      className="navbar is-fixed-top"
      role="navigation"
      aria-label="main navigation"
      headerVisible={shouldHeaderBeVisible}
      isHome={isHome}
      menuIsOpen={menuIsOpen}
    >
      <div class="navbar-brand">
        <a class="navbar-item" href="/">
          <img src={"/logo.svg"} alt="Logo" />
        </a>

        <a
          role="button"
          class={`navbar-burger ${isActiveClassName}`}
          aria-label="menu"
          aria-expanded="false"
          data-target="navbar"
          onClick={() => setMenuIsOpen(!menuIsOpen)}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div id="navbar" class={`navbar-menu ${isActiveClassName}`}>
        <div class="navbar-end">
          <div class="navbar-item">
            <a class="navbar-item navbar-item-container" href="/about">
              About
            </a>
            <a
              class="navbar-item"
              target="_blank"
              rel="noreferrer"
              href="https://instagram.com/melikemesin.artstudio?igshid=1j7yjiucgl2xe"
            >
              Instagram
            </a>
          </div>
        </div>
      </div>
    </Navbar>
  );
}

export default Header;
