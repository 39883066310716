import SEOHeader from "../components/SEOHeader";
import Contact from "../components/Contact";
import Content from "../i18n/de";
import VideoList from "../components/VideoList";
import Videojs from "./../video.js";
import styled from "styled-components";

const videoJsOptions = {
  autoplay: true,
  loop: true,
  controls: false,
  sources: [
    {
      src: "/videos/StartVideo.mp4",
      type: "video/mp4",
    },
  ],
};

const HomeContainer = styled.div`
  padding: 300px 0;
  @media (max-width: 992px) {
    padding: 100px 20px;
  }
`;

function Home() {
  return (
    <>
      <SEOHeader {...Content.meta.home} />
      <HomeContainer>
        <div class="container">
          <Videojs {...videoJsOptions} />
          <p
            style={{
              textAlign: "right",
              color: "#000",
              margin: "10px 0 0 0",
              fontWeight: 500,
            }}
          >
            The Balance between hardness and fragility
          </p>
        </div>
      </HomeContainer>
      <VideoList />
      <Contact />
    </>
  );
}

export default Home;
