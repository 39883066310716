import styled from "styled-components";

const ContactContainer = styled.div`
  p,
  a {
    color: #000;
  }
  @media (max-width: 992px) {
    text-align: center;
  }
`;

function Contact() {
  return (
    <ContactContainer>
      <div class="container">
        <div class="column is-offset-9 is-2">
          <p>
            <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />
            <br />
            <a href="mailto:design@melike-mesin.de">design@melike-mesin.de</a>
            <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />
            <br /> <br />
          </p>
        </div>
      </div>
    </ContactContainer>
  );
}

export default Contact;
