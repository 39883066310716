import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import videojs from "video.js";
import "video.js/dist/video-js.min.css";
import projects from "./../projects.json";

const VideoCarouselContainer = styled.section`
  width: 80%;
  margin: 0 auto;
  .text-box {
    padding: 0 20px;
    label {
      text-transform: uppercase;
      font-size: 20px;
      color: #000;
    }
    h3 {
      font-size: 54px;
      margin: 0 0 60px;
    }
    .info {
      color: #000;
      font-weight: 500;
    }
    p {
      font-size: 20px;
    }
  }
  @media (max-width: 768px) {
    .text-box {
      padding: 0;
      h3 {
        font-size: 36px;
        margin: 0 0 30px;
      }
    }
    width: 100%;
  }
`;

const BackButton = styled.button`
  position: absolute;
  left: 20px;
  top: 50%;
  background: none;
  border: none;
  font-size: 16px;
  text-transform: uppercase;
  outline: none;
  display: flex;
  cursor: pointer;
  img {
    height: 20px;
  }
  &:hover {
    span {
      border-bottom: 1px solid #000;
    }
  }
  @media (max-width: 768px) {
    top: 100%;
    left: 0;
  }
`;

const NextButton = styled.button`
  position: absolute;
  right: 20px;
  top: 50%;
  background: none;
  border: none;
  font-size: 16px;
  text-transform: uppercase;
  outline: none;
  display: flex;
  cursor: pointer;
  img {
    height: 20px;
  }
  &:hover {
    span {
      border-bottom: 1px solid #000;
    }
  }
  @media (max-width: 768px) {
    top: 100%;
    right: 0;
  }
`;

var player = undefined;
function VideoCarousel() {
  let { id } = useParams();
  const [currentItem, setCurrentItem] = useState(
    projects.items.find((item) => {
      return item.id === id;
    }) || projects.items[0]
  );
  const [currentIndex, setCurrentIndex] = useState(
    projects.items
      .map(function (o) {
        return o.id;
      })
      .indexOf(currentItem.id)
  );

  const playerRef = useRef();

  useEffect(() => {
    player = videojs(
      playerRef.current,
      { autoplay: false, muted: false, loop: true },
      () => {
        player.src(currentItem.sources);
      }
    );
  }, []);

  useEffect(() => {
    player.src(currentItem.sources);
    player.poster(currentItem.poster);
  }, [currentItem]);

  const goBack = () => {
    const newIndex =
      currentIndex > 0 ? currentIndex - 1 : projects.items.length - 1;
    setCurrentIndex(newIndex);
    setCurrentItem(projects.items[newIndex]);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const goNext = () => {
    const newIndex =
      currentIndex < projects.items.length - 1 ? currentIndex + 1 : 0;
    setCurrentIndex(newIndex);
    setCurrentItem(projects.items[newIndex]);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div style={{ position: "relative" }}>
      <VideoCarouselContainer>
        <div class="container">
          <div class="columns is-multiline">
            <div class="column is-8">
              <div data-vjs-player>
                <video
                  ref={playerRef}
                  className="video-js vjs-16-9 vjs-theme-custom"
                  controls
                  preload="auto"
                  poster={currentItem.poster}
                  height="100%"
                  width="100%"
                />
              </div>
            </div>
            <div class="column is-4">
              <div class="text-box">
                <label>{currentItem.title}</label>
                <h3>{currentItem.subtitle}</h3>
                <p class="info">{currentItem.info}</p>
                <p>{currentItem.description}</p>
              </div>
            </div>
          </div>
        </div>
        <BackButton onClick={goBack}>
          <img src="/icons/ic_arrow_left.svg" alt="" />
          <span>Back</span>
        </BackButton>
        <NextButton onClick={goNext}>
          <span>Next</span> <img src="/icons/ic_arrow_right.svg" alt="" />
        </NextButton>
      </VideoCarouselContainer>
    </div>
  );
}

export default VideoCarousel;
