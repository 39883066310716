import styled from "styled-components";
import projects from "./../projects.json";

const VideoSection = styled.section`
  padding: 60px 20px;
  @media (max-width: 767px) {
    padding: 20px 10px;
  }
`;

const VideoItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const VideoItem = styled.div`
  display: flex;
  flex: 0 1 100%;
  margin-bottom: 30px;
  justify-content: ${(props) =>
    props.position === "left"
      ? "flex-start"
      : props.position === "right"
      ? "flex-end"
      : "center"};
  background: ${(props) => `url(${props.background}) no-repeat center center`};
  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  display: flex;
  img {
    max-height: 1000px;
  }
  .overlay {
    display: none;
    text-align: ${(props) => (props.position === "left" ? "right" : "left")};
    position: absolute;
    flex-direction: column;
    justify-content: flex-end;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 40px;
    h3 {
      font-size: 80px;
      color: #fff;
      line-height: 1;
      margin: 0 0 5px 0;
    }
    h4 {
      color: #fff;
      line-height: 1;
      font-size: 35px;
      margin: 0 0 30px 0;
    }
    p {
      color: #fff;
      margin: 0;
      font-size: 20px;
    }
    img {
      width: 50px;
      position: absolute;
      top: calc(50% - 20px);
      left: calc(50% - 25px);
    }
    @media (max-width: 767px) {
      text-align: left;
      padding: 15px;
      h3 {
        font-size: 32px;
        margin: 0 0 5px 0;
      }
      h4 {
        font-size: 20px;
        margin: 0 0 10px 0;
      }
      p {
        font-size: 16px;
      }
      img {
        display: none;
      }
    }
  }
  &:hover {
    .overlay {
      // display: flex;
    }
  }
`;

function VideoList() {
  return (
    <VideoSection>
      <div class="columns">
        <div class="column">
          <VideoItemContainer>
            {projects.items.map((item, i) => (
              <VideoItem key={i} position={item.position}>
                <ImageContainer position={item.position}>
                  <img src={item.img} alt={item.title} />
                  <div class="overlay">
                    <h3>{item.title}</h3>
                    <h4>{item.subtitle}</h4>
                    <p>{item.info}</p>
                    <img src={"/images/symbol.png"} alt="" />
                  </div>
                </ImageContainer>
              </VideoItem>
            ))}
          </VideoItemContainer>
        </div>
      </div>
    </VideoSection>
  );
}

export default VideoList;
