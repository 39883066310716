import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Header from "../components/Header";
// import Footer from "../components/Footer";
import { useState } from "react";
import SEOHeader from "../components/SEOHeader";
import Content from "../i18n/de";
// Pages
import Home from "./Home";
import About from "./About";
import Projects from "./Projects";
import Impressum from "./Impressum";
import Datenschutz from "./Datenschutz";

function App() {
  const [headerVisible, setHeaderVisible] = useState(true);

  return (
    <Router>
      <SEOHeader {...Content.meta.home} />
      <div className="App">
        <Header headerVisible={headerVisible} />
        <Switch>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/impressum">
            <Impressum />
          </Route>
          <Route path="/datenschutz">
            <Datenschutz />
          </Route>
          <Route path="/projects/:id">
            <Projects />
          </Route>
          <Route path="/projects">
            <Projects />
          </Route>
          <Route exact path="/">
            <Home setHeaderVisible={setHeaderVisible} />
          </Route>
          <Route path="*" exact={true}>
            <Redirect from="*" to="/" />
          </Route>
        </Switch>
        {/* <Footer /> */}
      </div>
    </Router>
  );
}

export default App;
