import SEOHeader from "../components/SEOHeader";
import Content from "../i18n/de";
import SectionHelper from "../components/SectionHelper";

function Datenschutz() {
  return (
    <>
      <SEOHeader {...Content.meta.datenschutz} />
      <SectionHelper>
        <h1>Datenschutz&shy;erkl&auml;rung</h1>
        <h2>1. Datenschutz auf einen Blick</h2>
        <h3>Allgemeine Hinweise</h3>{" "}
        <p>
          Die folgenden Hinweise geben einen einfachen &Uuml;berblick
          dar&uuml;ber, was mit Ihren personenbezogenen Daten passiert, wenn Sie
          diese Website besuchen. Personenbezogene Daten sind alle Daten, mit
          denen Sie pers&ouml;nlich identifiziert werden k&ouml;nnen.
          Ausf&uuml;hrliche Informationen zum Thema Datenschutz entnehmen Sie
          unserer unter diesem Text aufgef&uuml;hrten Datenschutzerkl&auml;rung.
        </p>
        <h3>Datenerfassung auf dieser Website</h3>{" "}
        <h4>
          Wer ist verantwortlich f&uuml;r die Datenerfassung auf dieser Website?
        </h4>{" "}
        <p>
          Die Datenverarbeitung auf dieser Website erfolgt durch den
          Websitebetreiber. Dessen Kontaktdaten k&ouml;nnen Sie dem Abschnitt
          &bdquo;Hinweis zur Verantwortlichen Stelle&ldquo; in dieser
          Datenschutzerkl&auml;rung entnehmen.
        </p>{" "}
        <h4>Wie erfassen wir Ihre Daten?</h4>{" "}
        <p>
          Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
          mitteilen. Hierbei kann es sich z.&nbsp;B. um Daten handeln, die Sie
          in ein Kontaktformular eingeben.
        </p>{" "}
        <p>
          Andere Daten werden automatisch oder nach Ihrer Einwilligung beim
          Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem
          technische Daten (z.&nbsp;B. Internetbrowser, Betriebssystem oder
          Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt
          automatisch, sobald Sie diese Website betreten.
        </p>{" "}
        <h4>Wof&uuml;r nutzen wir Ihre Daten?</h4>{" "}
        <p>
          Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung
          der Website zu gew&auml;hrleisten. Andere Daten k&ouml;nnen zur
          Analyse Ihres Nutzerverhaltens verwendet werden.
        </p>{" "}
        <h4>Welche Rechte haben Sie bez&uuml;glich Ihrer Daten?</h4>{" "}
        <p>
          Sie haben jederzeit das Recht, unentgeltlich Auskunft &uuml;ber
          Herkunft, Empf&auml;nger und Zweck Ihrer gespeicherten
          personenbezogenen Daten zu erhalten. Sie haben au&szlig;erdem ein
          Recht, die Berichtigung oder L&ouml;schung dieser Daten zu verlangen.
          Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben,
          k&ouml;nnen Sie diese Einwilligung jederzeit f&uuml;r die Zukunft
          widerrufen. Au&szlig;erdem haben Sie das Recht, unter bestimmten
          Umst&auml;nden die Einschr&auml;nkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein
          Beschwerderecht bei der zust&auml;ndigen Aufsichtsbeh&ouml;rde zu.
        </p>{" "}
        <p>
          Hierzu sowie zu weiteren Fragen zum Thema Datenschutz k&ouml;nnen Sie
          sich jederzeit an uns wenden.
        </p>
        <h3>Analyse-Tools und Tools von Dritt&shy;anbietern</h3>{" "}
        <p>
          Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch
          ausgewertet werden. Das geschieht vor allem mit sogenannten
          Analyseprogrammen.
        </p>{" "}
        <p>
          Detaillierte Informationen zu diesen Analyseprogrammen finden Sie in
          der folgenden Datenschutzerkl&auml;rung.
        </p>
        <h2>2. Hosting und Content Delivery Networks (CDN)</h2>
        <h3>Externes Hosting</h3>{" "}
        <p>
          Diese Website wird bei einem externen Dienstleister gehostet (Hoster).
          Die personenbezogenen Daten, die auf dieser Website erfasst werden,
          werden auf den Servern des Hosters gespeichert. Hierbei kann es sich
          v. a. um IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten,
          Vertragsdaten, Kontaktdaten, Namen, Websitezugriffe und sonstige
          Daten, die &uuml;ber eine Website generiert werden, handeln.
        </p>{" "}
        <p>
          Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserf&uuml;llung
          gegen&uuml;ber unseren potenziellen und bestehenden Kunden (Art. 6
          Abs. 1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und
          effizienten Bereitstellung unseres Online-Angebots durch einen
          professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO).
        </p>{" "}
        <p>
          Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur
          Erf&uuml;llung seiner Leistungspflichten erforderlich ist und unsere
          Weisungen in Bezug auf diese Daten befolgen.
        </p>{" "}
        <p>Wir setzen folgenden Hoster ein:</p>
        <p>
          ALL-INKL.COM - Neue Medien M&uuml;nnich
          <br />
          Inhaber: Ren&eacute; M&uuml;nnich
          <br />
          Hauptstra&szlig;e 68 | D-02742 Friedersdorf
          <br />
          <br />
          Telefon:+49 35872 353-10
          <br />
          Telefax: +49 35872 353-30
          <br />
          E-Mail:info@all-inkl.com
          <br />
          <br />
          Umsatzsteuer-ID: DE 212657916
          <br />
          Steuernummer: 208/251/03091
        </p>
        <h4>Abschluss eines Vertrages &uuml;ber Auftragsverarbeitung</h4>{" "}
        <p>
          Um die datenschutzkonforme Verarbeitung zu gew&auml;hrleisten, haben
          wir einen Vertrag &uuml;ber Auftragsverarbeitung mit unserem Hoster
          geschlossen.
        </p>
        <h2>3. Allgemeine Hinweise und Pflicht&shy;informationen</h2>
        <h3>Datenschutz</h3>{" "}
        <p>
          Die Betreiber dieser Seiten nehmen den Schutz Ihrer pers&ouml;nlichen
          Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
          vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften
          sowie dieser Datenschutzerkl&auml;rung.
        </p>{" "}
        <p>
          Wenn Sie diese Website benutzen, werden verschiedene personenbezogene
          Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie
          pers&ouml;nlich identifiziert werden k&ouml;nnen. Die vorliegende
          Datenschutzerkl&auml;rung erl&auml;utert, welche Daten wir erheben und
          wof&uuml;r wir sie nutzen. Sie erl&auml;utert auch, wie und zu welchem
          Zweck das geschieht.
        </p>{" "}
        <p>
          Wir weisen darauf hin, dass die Daten&uuml;bertragung im Internet
          (z.&nbsp;B. bei der Kommunikation per E-Mail) Sicherheitsl&uuml;cken
          aufweisen kann. Ein l&uuml;ckenloser Schutz der Daten vor dem Zugriff
          durch Dritte ist nicht m&ouml;glich.
        </p>
        <h3>Hinweis zur verantwortlichen Stelle</h3>{" "}
        <p>
          Die verantwortliche Stelle f&uuml;r die Datenverarbeitung auf dieser
          Website ist:
        </p>{" "}
        <p>
          ############## <br />
          ##############
          <br />
          ########
          <br />
          ##########
        </p>
        <p>
          Telefon: ###########
          <br />
          E-Mail: ############
        </p>
        <p>
          Verantwortliche Stelle ist die nat&uuml;rliche oder juristische
          Person, die allein oder gemeinsam mit anderen &uuml;ber die Zwecke und
          Mittel der Verarbeitung von personenbezogenen Daten (z.&nbsp;B. Namen,
          E-Mail-Adressen o. &Auml;.) entscheidet.
        </p>
        <h3>Speicherdauer</h3>{" "}
        <p>
          Soweit innerhalb dieser Datenschutzerkl&auml;rung keine speziellere
          Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten
          bei uns, bis der Zweck f&uuml;r die Datenverarbeitung entf&auml;llt.
          Wenn Sie ein berechtigtes L&ouml;schersuchen geltend machen oder eine
          Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten
          gel&ouml;scht, sofern wir keine anderen rechtlich
          zul&auml;ssigen&nbsp; Gr&uuml;nde f&uuml;r die Speicherung Ihrer
          personenbezogenen Daten haben (z.B. steuer- oder handelsrechtliche
          Aufbewahrungsfristen); im letztgenannten Fall erfolgt die
          L&ouml;schung nach Fortfall dieser Gr&uuml;nde.
        </p>
        <h3>Hinweis zur Datenweitergabe in die USA</h3>{" "}
        <p>
          Auf unserer Website sind unter anderem Tools von Unternehmen mit Sitz
          in den USA eingebunden. Wenn diese Tools aktiv sind, k&ouml;nnen Ihre
          personenbezogenen Daten an die US-Server der jeweiligen Unternehmen
          weitergegeben werden. Wir weisen darauf hin, dass die USA kein
          sicherer Drittstaat im Sinne des EU-Datenschutzrechts sind.
          US-Unternehmen sind dazu verpflichtet, personenbezogene Daten an
          Sicherheitsbeh&ouml;rden herauszugeben, ohne dass Sie als Betroffener
          hiergegen gerichtlich vorgehen k&ouml;nnten. Es kann daher nicht
          ausgeschlossen werden, dass US-Beh&ouml;rden (z.B. Geheimdienste) Ihre
          auf US-Servern befindlichen Daten zu &Uuml;berwachungszwecken
          verarbeiten, auswerten und dauerhaft speichern. Wir haben auf diese
          Verarbeitungst&auml;tigkeiten keinen Einfluss.
        </p>
        <h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>{" "}
        <p>
          Viele Datenverarbeitungsvorg&auml;nge sind nur mit Ihrer
          ausdr&uuml;cklichen Einwilligung m&ouml;glich. Sie k&ouml;nnen eine
          bereits erteilte Einwilligung jederzeit widerrufen. Die
          Rechtm&auml;&szlig;igkeit der bis zum Widerruf erfolgten
          Datenverarbeitung bleibt vom Widerruf unber&uuml;hrt.
        </p>
        <h3>
          Widerspruchsrecht gegen die Datenerhebung in besonderen F&auml;llen
          sowie gegen Direktwerbung (Art. 21 DSGVO)
        </h3>{" "}
        <p>
          WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER
          F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GR&Uuml;NDEN, DIE
          SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG
          IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH
          F&Uuml;R EIN AUF DIESE BESTIMMUNGEN GEST&Uuml;TZTES PROFILING. DIE
          JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT,
          ENTNEHMEN SIE DIESER DATENSCHUTZERKL&Auml;RUNG. WENN SIE WIDERSPRUCH
          EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT
          MEHR VERARBEITEN, ES SEI DENN, WIR K&Ouml;NNEN ZWINGENDE
          SCHUTZW&Uuml;RDIGE GR&Uuml;NDE F&Uuml;R DIE VERARBEITUNG NACHWEISEN,
          DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN &Uuml;BERWIEGEN ODER DIE
          VERARBEITUNG DIENT DER GELTENDMACHUNG, AUS&Uuml;BUNG ODER VERTEIDIGUNG
          VON RECHTSANSPR&Uuml;CHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).
        </p>{" "}
        <p>
          WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU
          BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE
          VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE
          DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH F&Uuml;R DAS PROFILING,
          SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE
          WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT
          MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21
          ABS. 2 DSGVO).
        </p>
        <h3>
          Beschwerde&shy;recht bei der zust&auml;ndigen
          Aufsichts&shy;beh&ouml;rde
        </h3>{" "}
        <p>
          Im Falle von Verst&ouml;&szlig;en gegen die DSGVO steht den
          Betroffenen ein Beschwerderecht bei einer Aufsichtsbeh&ouml;rde,
          insbesondere in dem Mitgliedstaat ihres gew&ouml;hnlichen Aufenthalts,
          ihres Arbeitsplatzes oder des Orts des mutma&szlig;lichen
          Versto&szlig;es zu. Das Beschwerderecht besteht unbeschadet
          anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
        </p>
        <h3>Recht auf Daten&shy;&uuml;bertrag&shy;barkeit</h3>{" "}
        <p>
          Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
          oder in Erf&uuml;llung eines Vertrags automatisiert verarbeiten, an
          sich oder an einen Dritten in einem g&auml;ngigen, maschinenlesbaren
          Format aush&auml;ndigen zu lassen. Sofern Sie die direkte
          &Uuml;bertragung der Daten an einen anderen Verantwortlichen
          verlangen, erfolgt dies nur, soweit es technisch machbar ist.
        </p>
        <h3>SSL- bzw. TLS-Verschl&uuml;sselung</h3>{" "}
        <p>
          Diese Seite nutzt aus Sicherheitsgr&uuml;nden und zum Schutz der
          &Uuml;bertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen
          oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-
          bzw. TLS-Verschl&uuml;sselung. Eine verschl&uuml;sselte Verbindung
          erkennen Sie daran, dass die Adresszeile des Browsers von
          &bdquo;http://&ldquo; auf &bdquo;https://&ldquo; wechselt und an dem
          Schloss-Symbol in Ihrer Browserzeile.
        </p>{" "}
        <p>
          Wenn die SSL- bzw. TLS-Verschl&uuml;sselung aktiviert ist, k&ouml;nnen
          die Daten, die Sie an uns &uuml;bermitteln, nicht von Dritten
          mitgelesen werden.
        </p>
        <h3>Auskunft, L&ouml;schung und Berichtigung</h3>{" "}
        <p>
          Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit
          das Recht auf unentgeltliche Auskunft &uuml;ber Ihre gespeicherten
          personenbezogenen Daten, deren Herkunft und Empf&auml;nger und den
          Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder
          L&ouml;schung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema
          personenbezogene Daten k&ouml;nnen Sie sich jederzeit an uns wenden.
        </p>
        <h3>Recht auf Einschr&auml;nkung der Verarbeitung</h3>{" "}
        <p>
          Sie haben das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen. Hierzu k&ouml;nnen Sie sich
          jederzeit an uns wenden. Das Recht auf Einschr&auml;nkung der
          Verarbeitung besteht in folgenden F&auml;llen:
        </p>{" "}
        <ul>
          {" "}
          <li>
            Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
            personenbezogenen Daten bestreiten, ben&ouml;tigen wir in der Regel
            Zeit, um dies zu &uuml;berpr&uuml;fen. F&uuml;r die Dauer der
            Pr&uuml;fung haben Sie das Recht, die Einschr&auml;nkung der
            Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
          </li>{" "}
          <li>
            Wenn die Verarbeitung Ihrer personenbezogenen Daten
            unrechtm&auml;&szlig;ig geschah/geschieht, k&ouml;nnen Sie statt der
            L&ouml;schung die Einschr&auml;nkung der Datenverarbeitung
            verlangen.
          </li>{" "}
          <li>
            Wenn wir Ihre personenbezogenen Daten nicht mehr ben&ouml;tigen, Sie
            sie jedoch zur Aus&uuml;bung, Verteidigung oder Geltendmachung von
            Rechtsanspr&uuml;chen ben&ouml;tigen, haben Sie das Recht, statt der
            L&ouml;schung die Einschr&auml;nkung der Verarbeitung Ihrer
            personenbezogenen Daten zu verlangen.
          </li>{" "}
          <li>
            Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt
            haben, muss eine Abw&auml;gung zwischen Ihren und unseren Interessen
            vorgenommen werden. Solange noch nicht feststeht, wessen Interessen
            &uuml;berwiegen, haben Sie das Recht, die Einschr&auml;nkung der
            Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
          </li>{" "}
        </ul>{" "}
        <p>
          Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten
          eingeschr&auml;nkt haben, d&uuml;rfen diese Daten &ndash; von ihrer
          Speicherung abgesehen &ndash; nur mit Ihrer Einwilligung oder zur
          Geltendmachung, Aus&uuml;bung oder Verteidigung von
          Rechtsanspr&uuml;chen oder zum Schutz der Rechte einer anderen
          nat&uuml;rlichen oder juristischen Person oder aus Gr&uuml;nden eines
          wichtigen &ouml;ffentlichen Interesses der Europ&auml;ischen Union
          oder eines Mitgliedstaats verarbeitet werden.
        </p>
        <h3>Widerspruch gegen Werbe-E-Mails</h3>{" "}
        <p>
          Der Nutzung von im Rahmen der Impressumspflicht ver&ouml;ffentlichten
          Kontaktdaten zur &Uuml;bersendung von nicht ausdr&uuml;cklich
          angeforderter Werbung und Informationsmaterialien wird hiermit
          widersprochen. Die Betreiber der Seiten behalten sich
          ausdr&uuml;cklich rechtliche Schritte im Falle der unverlangten
          Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.
        </p>
        <h2>4. Datenerfassung auf dieser Website</h2>
        <h3>Cookies</h3>{" "}
        <p>
          Unsere Internetseiten verwenden so genannte &bdquo;Cookies&ldquo;.
          Cookies sind kleine Textdateien und richten auf Ihrem Endger&auml;t
          keinen Schaden an. Sie werden entweder vor&uuml;bergehend f&uuml;r die
          Dauer einer Sitzung (Session-Cookies) oder dauerhaft (permanente
          Cookies) auf Ihrem Endger&auml;t gespeichert. Session-Cookies werden
          nach Ende Ihres Besuchs automatisch gel&ouml;scht. Permanente Cookies
          bleiben auf Ihrem Endger&auml;t gespeichert, bis Sie diese selbst
          l&ouml;schen&nbsp;oder eine automatische L&ouml;schung durch Ihren
          Webbrowser erfolgt.
        </p>{" "}
        <p>
          Teilweise k&ouml;nnen auch Cookies von Drittunternehmen auf Ihrem
          Endger&auml;t gespeichert werden, wenn Sie unsere Seite betreten
          (Third-Party-Cookies). Diese erm&ouml;glichen uns oder Ihnen die
          Nutzung bestimmter Dienstleistungen des Drittunternehmens (z.B.
          Cookies zur Abwicklung von Zahlungsdienstleistungen).
        </p>{" "}
        <p>
          Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind
          technisch notwendig, da bestimmte Websitefunktionen ohne diese nicht
          funktionieren w&uuml;rden (z.B. die Warenkorbfunktion oder die Anzeige
          von Videos). Andere Cookies dienen dazu, das Nutzerverhalten
          auszuwerten&nbsp;oder Werbung anzuzeigen.
        </p>{" "}
        <p>
          Cookies, die zur Durchf&uuml;hrung des elektronischen
          Kommunikationsvorgangs (notwendige Cookies) oder zur Bereitstellung
          bestimmter, von Ihnen erw&uuml;nschter Funktionen (funktionale
          Cookies, z. B. f&uuml;r die Warenkorbfunktion) oder zur Optimierung
          der Website (z.B. Cookies zur Messung des Webpublikums) erforderlich
          sind, werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert,
          sofern keine andere Rechtsgrundlage angegeben wird. Der
          Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von
          Cookies zur technisch fehlerfreien und optimierten Bereitstellung
          seiner Dienste. Sofern eine Einwilligung zur Speicherung von Cookies
          abgefragt wurde, erfolgt die Speicherung der betreffenden Cookies
          ausschlie&szlig;lich auf Grundlage dieser Einwilligung (Art. 6 Abs. 1
          lit. a DSGVO); die Einwilligung ist jederzeit widerrufbar.
        </p>{" "}
        <p>
          Sie k&ouml;nnen Ihren Browser so einstellen, dass Sie &uuml;ber das
          Setzen von Cookies informiert werden und Cookies nur im Einzelfall
          erlauben, die Annahme von Cookies f&uuml;r bestimmte F&auml;lle oder
          generell ausschlie&szlig;en sowie das automatische L&ouml;schen der
          Cookies beim Schlie&szlig;en des Browsers aktivieren. Bei der
          Deaktivierung von Cookies kann die Funktionalit&auml;t dieser Website
          eingeschr&auml;nkt sein.
        </p>{" "}
        <p>
          Soweit Cookies von Drittunternehmen oder zu Analysezwecken eingesetzt
          werden, werden wir Sie hier&uuml;ber im Rahmen dieser
          Datenschutzerkl&auml;rung gesondert informieren und ggf. eine
          Einwilligung abfragen.
        </p>
        <h3>Server-Log-Dateien</h3>{" "}
        <p>
          Der Provider der Seiten erhebt und speichert automatisch Informationen
          in so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns
          &uuml;bermittelt. Dies sind:
        </p>{" "}
        <ul>
          {" "}
          <li>Browsertyp und Browserversion</li>{" "}
          <li>verwendetes Betriebssystem</li> <li>Referrer URL</li>{" "}
          <li>Hostname des zugreifenden Rechners</li>{" "}
          <li>Uhrzeit der Serveranfrage</li> <li>IP-Adresse</li>{" "}
        </ul>{" "}
        <p>
          Eine Zusammenf&uuml;hrung dieser Daten mit anderen Datenquellen wird
          nicht vorgenommen.
        </p>{" "}
        <p>
          Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
          lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an
          der technisch fehlerfreien Darstellung und der Optimierung seiner
          Website &ndash; hierzu m&uuml;ssen die Server-Log-Files erfasst
          werden.
        </p>
        <h3>Kontaktformular</h3>{" "}
        <p>
          Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre
          Angaben aus dem Anfrageformular inklusive der von Ihnen dort
          angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und f&uuml;r
          den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben
          wir nicht ohne Ihre Einwilligung weiter.
        </p>{" "}
        <p>
          Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
          lit. b DSGVO, sofern Ihre Anfrage mit der Erf&uuml;llung eines
          Vertrags zusammenh&auml;ngt oder zur Durchf&uuml;hrung
          vorvertraglicher Ma&szlig;nahmen erforderlich ist. In allen
          &uuml;brigen F&auml;llen beruht die Verarbeitung auf unserem
          berechtigten Interesse an der effektiven Bearbeitung der an uns
          gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
          Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt
          wurde.
        </p>{" "}
        <p>
          Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei
          uns, bis Sie uns zur L&ouml;schung auffordern, Ihre Einwilligung zur
          Speicherung widerrufen oder der Zweck f&uuml;r die Datenspeicherung
          entf&auml;llt (z.&nbsp;B. nach abgeschlossener Bearbeitung Ihrer
          Anfrage). Zwingende gesetzliche Bestimmungen &ndash; insbesondere
          Aufbewahrungsfristen &ndash; bleiben unber&uuml;hrt.
        </p>
        <h3>Anfrage per E-Mail, Telefon oder Telefax</h3>{" "}
        <p>
          Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre
          Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten
          (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns
          gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre
          Einwilligung weiter.
        </p>{" "}
        <p>
          Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
          lit. b DSGVO, sofern Ihre Anfrage mit der Erf&uuml;llung eines
          Vertrags zusammenh&auml;ngt oder zur Durchf&uuml;hrung
          vorvertraglicher Ma&szlig;nahmen erforderlich ist. In allen
          &uuml;brigen F&auml;llen beruht die Verarbeitung auf unserem
          berechtigten Interesse an der effektiven Bearbeitung der an uns
          gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
          Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt
          wurde.
        </p>{" "}
        <p>
          Die von Ihnen an uns per Kontaktanfragen &uuml;bersandten Daten
          verbleiben bei uns, bis Sie uns zur L&ouml;schung auffordern, Ihre
          Einwilligung zur Speicherung widerrufen oder der Zweck f&uuml;r die
          Datenspeicherung entf&auml;llt (z.&nbsp;B. nach abgeschlossener
          Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen
          &ndash; insbesondere gesetzliche Aufbewahrungsfristen &ndash; bleiben
          unber&uuml;hrt.
        </p>
        <h2>5. Soziale Medien</h2>
        <h3>Facebook Plugins (Like &amp; Share-Button)</h3>{" "}
        <p>
          Auf dieser Website sind Plugins des sozialen Netzwerks Facebook
          integriert. Anbieter dieses Dienstes ist die Facebook Ireland Limited,
          4 Grand Canal Square, Dublin 2, Irland. Die erfassten Daten werden
          nach Aussage von Facebook jedoch auch in die USA und in andere
          Drittl&auml;nder &uuml;bertragen.
        </p>{" "}
        <p>
          Die Facebook Plugins erkennen Sie an dem Facebook-Logo oder dem
          &bdquo;Like-Button&ldquo; (&bdquo;Gef&auml;llt mir&ldquo;) auf dieser
          Website. Eine &Uuml;bersicht &uuml;ber die Facebook Plugins finden Sie
          hier:{" "}
          <a
            href="https://developers.facebook.com/docs/plugins/?locale=de_DE"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://developers.facebook.com/docs/plugins/?locale=de_DE
          </a>
          .
        </p>{" "}
        <p>
          Wenn Sie diese Website besuchen, wird &uuml;ber das Plugin eine
          direkte Verbindung zwischen Ihrem Browser und dem Facebook-Server
          hergestellt. Facebook erh&auml;lt dadurch die Information, dass Sie
          mit Ihrer IP-Adresse diese Website besucht haben. Wenn Sie den
          Facebook &bdquo;Like-Button&ldquo; anklicken, w&auml;hrend Sie in
          Ihrem Facebook-Account eingeloggt sind, k&ouml;nnen Sie die Inhalte
          dieser Website auf Ihrem Facebook-Profil verlinken. Dadurch kann
          Facebook den Besuch dieser Website Ihrem Benutzerkonto zuordnen. Wir
          weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom
          Inhalt der &uuml;bermittelten Daten sowie deren Nutzung durch Facebook
          erhalten. Weitere Informationen hierzu finden Sie in der
          Datenschutzerkl&auml;rung von Facebook unter:{" "}
          <a
            href="https://de-de.facebook.com/privacy/explanation"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://de-de.facebook.com/privacy/explanation
          </a>
          .
        </p>{" "}
        <p>
          Wenn Sie nicht w&uuml;nschen, dass Facebook den Besuch dieser Website
          Ihrem Facebook-Nutzerkonto zuordnen kann, loggen Sie sich bitte aus
          Ihrem Facebook-Benutzerkonto aus.
        </p>{" "}
        <p>
          Die Verwendung der Facebook Plugins erfolgt auf Grundlage von Art. 6
          Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
          Interesse an einer m&ouml;glichst umfangreichen Sichtbarkeit in den
          Sozialen Medien. Sofern eine entsprechende Einwilligung abgefragt
          wurde, erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage von
          Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit
          widerrufbar.
        </p>{" "}
        <p>
          Die Daten&uuml;bertragung in die USA wird auf die
          Standardvertragsklauseln der EU-Kommission gest&uuml;tzt. Details
          finden Sie hier:{" "}
          <a
            href="https://www.facebook.com/legal/EU_data_transfer_addendum"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.facebook.com/legal/EU_data_transfer_addendum
          </a>
          ,{" "}
          <a
            href="https://de-de.facebook.com/help/566994660333381"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://de-de.facebook.com/help/566994660333381
          </a>{" "}
          und{" "}
          <a
            href="https://www.facebook.com/policy.php"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.facebook.com/policy.php
          </a>
          .
        </p>
        <h3>Twitter Plugin</h3>{" "}
        <p>
          Auf dieser Website sind Funktionen des Dienstes Twitter eingebunden.
          Diese Funktionen werden angeboten durch die Twitter International
          Company, One Cumberland Place, Fenian Street, Dublin 2, D02 AX07,
          Irland. Durch das Benutzen von Twitter und der Funktion
          &bdquo;Re-Tweet&ldquo; werden die von Ihnen besuchten Websites mit
          Ihrem Twitter-Account verkn&uuml;pft und anderen Nutzern bekannt
          gegeben. Dabei werden auch Daten an Twitter &uuml;bertragen. Wir
          weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom
          Inhalt der &uuml;bermittelten Daten sowie deren Nutzung durch Twitter
          erhalten. Weitere Informationen hierzu finden Sie in der
          Datenschutzerkl&auml;rung von Twitter unter:{" "}
          <a
            href="https://twitter.com/de/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://twitter.com/de/privacy
          </a>
          .
        </p>{" "}
        <p>
          Die Verwendung des Twitter-Plugins erfolgt auf Grundlage von Art. 6
          Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
          Interesse an einer m&ouml;glichst umfangreichen Sichtbarkeit in den
          Sozialen Medien. Sofern eine entsprechende Einwilligung abgefragt
          wurde, erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage von
          Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit
          widerrufbar.
        </p>{" "}
        <p>
          Die Daten&uuml;bertragung in die USA wird auf die
          Standardvertragsklauseln der EU-Kommission gest&uuml;tzt. Details
          finden Sie hier:{" "}
          <a
            href="https://gdpr.twitter.com/en/controller-to-controller-transfers.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://gdpr.twitter.com/en/controller-to-controller-transfers.html
          </a>
          .
        </p>{" "}
        <p>
          Ihre Datenschutzeinstellungen bei Twitter k&ouml;nnen Sie in den
          Konto-Einstellungen unter{" "}
          <a
            href="https://twitter.com/account/settings"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://twitter.com/account/settings
          </a>{" "}
          &auml;ndern.
        </p>
        <h3>Instagram Plugin</h3>{" "}
        <p>
          Auf dieser Website sind Funktionen des Dienstes Instagram eingebunden.
          Diese Funktionen werden angeboten durch die Facebook Ireland Limited,
          4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland
          integriert.
        </p>{" "}
        <p>
          Wenn Sie in Ihrem Instagram-Account eingeloggt sind, k&ouml;nnen Sie
          durch Anklicken des Instagram-Buttons die Inhalte dieser Website mit
          Ihrem Instagram-Profil verlinken. Dadurch kann Instagram den Besuch
          dieser Website Ihrem Benutzerkonto zuordnen. Wir weisen darauf hin,
          dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der
          &uuml;bermittelten Daten sowie deren Nutzung durch Instagram erhalten.
        </p>{" "}
        <p>
          Die Speicherung und Analyse der Daten erfolgt auf Grundlage von Art. 6
          Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
          Interesse an einer m&ouml;glichst umfangreichen Sichtbarkeit in den
          Sozialen Medien. Sofern eine entsprechende Einwilligung abgefragt
          wurde, erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage von
          Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit
          widerrufbar.
        </p>{" "}
        <p>
          Die Daten&uuml;bertragung in die USA wird auf die
          Standardvertragsklauseln der EU-Kommission gest&uuml;tzt. Details
          finden Sie hier:{" "}
          <a
            href="https://www.facebook.com/legal/EU_data_transfer_addendum"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.facebook.com/legal/EU_data_transfer_addendum
          </a>
          ,{" "}
          <a
            href="https://help.instagram.com/519522125107875"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://help.instagram.com/519522125107875
          </a>{" "}
          und{" "}
          <a
            href="https://de-de.facebook.com/help/566994660333381"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://de-de.facebook.com/help/566994660333381
          </a>
          .
        </p>{" "}
        <p>
          Weitere Informationen hierzu finden Sie in der
          Datenschutzerkl&auml;rung von Instagram:{" "}
          <a
            href="https://instagram.com/about/legal/privacy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://instagram.com/about/legal/privacy/
          </a>
          .
        </p>
        <h2>6. Newsletter</h2>
        <h3>Newsletter&shy;daten</h3>{" "}
        <p>
          Wenn Sie den auf der Website angebotenen Newsletter beziehen
          m&ouml;chten, ben&ouml;tigen wir von Ihnen eine E-Mail-Adresse sowie
          Informationen, welche uns die &Uuml;berpr&uuml;fung gestatten, dass
          Sie der Inhaber der angegebenen E-Mail-Adresse sind und mit dem
          Empfang des Newsletters einverstanden sind. Weitere Daten werden nicht
          bzw. nur auf freiwilliger Basis erhoben. Diese Daten verwenden wir
          ausschlie&szlig;lich f&uuml;r den Versand der angeforderten
          Informationen und geben diese nicht an Dritte weiter.
        </p>{" "}
        <p>
          Die Verarbeitung der in das Newsletteranmeldeformular eingegebenen
          Daten erfolgt ausschlie&szlig;lich auf Grundlage Ihrer Einwilligung
          (Art. 6 Abs. 1 lit. a DSGVO). Die erteilte Einwilligung zur
          Speicherung der Daten, der E-Mail-Adresse sowie deren Nutzung zum
          Versand des Newsletters k&ouml;nnen Sie jederzeit widerrufen, etwa
          &uuml;ber den &bdquo;Austragen&ldquo;-Link im Newsletter. Die
          Rechtm&auml;&szlig;igkeit der bereits erfolgten
          Datenverarbeitungsvorg&auml;nge bleibt vom Widerruf unber&uuml;hrt.
        </p>{" "}
        <p>
          Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten
          Daten werden von uns bis zu Ihrer Austragung aus dem Newsletter bei
          uns bzw. dem Newsletterdiensteanbieter gespeichert und nach der
          Abbestellung des Newsletters oder nach Zweckfortfall aus der
          Newsletterverteilerliste gel&ouml;scht. Wir behalten uns vor,
          E-Mail-Adressen aus unserem Newsletterverteiler nach eigenem Ermessen
          im Rahmen unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f
          DSGVO zu l&ouml;schen oder zu sperren.
        </p>{" "}
        <p>
          Nach Ihrer Austragung aus der Newsletterverteilerliste wird Ihre
          E-Mail-Adresse bei uns bzw. dem Newsletterdiensteanbieter ggf. in
          einer Blacklist gespeichert, um k&uuml;nftige Mailings zu verhindern.
          Die Daten aus der Blacklist werden nur f&uuml;r diesen Zweck verwendet
          und nicht mit anderen Daten zusammengef&uuml;hrt. Dies dient sowohl
          Ihrem Interesse als auch unserem Interesse an der Einhaltung der
          gesetzlichen Vorgaben beim Versand von Newslettern (berechtigtes
          Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO). Die Speicherung in
          der Blacklist ist zeitlich nicht befristet.{" "}
          <strong>
            Sie k&ouml;nnen der Speicherung widersprechen, sofern Ihre
            Interessen unser berechtigtes Interesse &uuml;berwiegen.
          </strong>
        </p>
        <h2>7. Plugins und Tools</h2>
        <h3>YouTube mit erweitertem Datenschutz</h3>{" "}
        <p>
          Diese Website bindet Videos der YouTube ein. Betreiber der Seiten ist
          die Google Ireland Limited (&bdquo;Google&ldquo;), Gordon House,
          Barrow Street, Dublin 4, Irland.
        </p>{" "}
        <p>
          Wir nutzen YouTube im erweiterten Datenschutzmodus. Dieser Modus
          bewirkt laut YouTube, dass YouTube keine Informationen &uuml;ber die
          Besucher auf dieser Website speichert, bevor diese sich das Video
          ansehen. Die Weitergabe von Daten an YouTube-Partner wird durch den
          erweiterten Datenschutzmodus hingegen nicht zwingend ausgeschlossen.
          So stellt YouTube &ndash; unabh&auml;ngig davon, ob Sie sich ein Video
          ansehen &ndash; eine Verbindung zum Google DoubleClick-Netzwerk her.
        </p>{" "}
        <p>
          Sobald Sie ein YouTube-Video auf dieser Website starten, wird eine
          Verbindung zu den Servern von YouTube hergestellt. Dabei wird dem
          YouTube-Server mitgeteilt, welche unserer Seiten Sie besucht haben.
          Wenn Sie in Ihrem YouTube-Account eingeloggt sind, erm&ouml;glichen
          Sie YouTube, Ihr Surfverhalten direkt Ihrem pers&ouml;nlichen Profil
          zuzuordnen. Dies k&ouml;nnen Sie verhindern, indem Sie sich aus Ihrem
          YouTube-Account ausloggen.
        </p>{" "}
        <p>
          Des Weiteren kann YouTube nach Starten eines Videos verschiedene
          Cookies auf Ihrem Endger&auml;t speichern oder vergleichbare
          Wiedererkennungstechnologien (z.B. Device-Fingerprinting) einsetzen.
          Auf diese Weise kann YouTube Informationen &uuml;ber Besucher dieser
          Website erhalten. Diese Informationen werden u. a. verwendet, um
          Videostatistiken zu erfassen, die Anwenderfreundlichkeit zu verbessern
          und Betrugsversuchen vorzubeugen.
        </p>{" "}
        <p>
          Gegebenenfalls k&ouml;nnen nach dem Start eines YouTube-Videos weitere
          Datenverarbeitungsvorg&auml;nge ausgel&ouml;st werden, auf die wir
          keinen Einfluss haben.
        </p>{" "}
        <p>
          Die Nutzung von YouTube erfolgt im Interesse einer ansprechenden
          Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes
          Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine
          entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
          ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die
          Einwilligung ist jederzeit widerrufbar.
        </p>{" "}
        <p>
          Weitere Informationen &uuml;ber Datenschutz bei YouTube finden Sie in
          deren Datenschutzerkl&auml;rung unter:{" "}
          <a
            href="https://policies.google.com/privacy?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://policies.google.com/privacy?hl=de
          </a>
          .
        </p>
        <h3>Vimeo</h3>{" "}
        <p>
          Diese Website nutzt Plugins des Videoportals Vimeo. Anbieter ist die
          Vimeo Inc., 555 West 18th Street, New York, New York 10011, USA.
        </p>{" "}
        <p>
          Wenn Sie eine unserer mit einem Vimeo-Video ausgestatteten Seiten
          besuchen, wird eine Verbindung zu den Servern von Vimeo hergestellt.
          Dabei wird dem Vimeo-Server mitgeteilt, welche unserer Seiten Sie
          besucht haben. Zudem erlangt Vimeo Ihre IP-Adresse. Dies gilt auch
          dann, wenn Sie nicht bei Vimeo eingeloggt sind oder keinen Account bei
          Vimeo besitzen. Die von Vimeo erfassten Informationen werden an den
          Vimeo-Server in den USA &uuml;bermittelt.
        </p>{" "}
        <p>
          Wenn Sie in Ihrem Vimeo-Account eingeloggt sind, erm&ouml;glichen Sie
          Vimeo, Ihr Surfverhalten direkt Ihrem pers&ouml;nlichen Profil
          zuzuordnen. Dies k&ouml;nnen Sie verhindern, indem Sie sich aus Ihrem
          Vimeo-Account ausloggen.
        </p>{" "}
        <p>
          Zur Wiedererkennung der Websitebesucher verwendet Vimeo Cookies bzw.
          vergleichbare Wiedererkennungstechnologien (z.B.
          Device-Fingerprinting).
        </p>{" "}
        <p>
          Die Nutzung von Vimeo erfolgt im Interesse einer ansprechenden
          Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes
          Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine
          entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
          ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die
          Einwilligung ist jederzeit widerrufbar.
        </p>{" "}
        <p>
          Die Daten&uuml;bertragung in die USA wird auf die
          Standardvertragsklauseln der EU-Kommission sowie nach Aussage von
          Vimeo auf &bdquo;berechtigte Gesch&auml;ftsinteressen&ldquo;
          gest&uuml;tzt. Details finden Sie hier:{" "}
          <a
            href="https://vimeo.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://vimeo.com/privacy
          </a>
          .
        </p>{" "}
        <p>
          Weitere Informationen zum Umgang mit Nutzerdaten finden Sie in der
          Datenschutzerkl&auml;rung von Vimeo unter:{" "}
          <a
            href="https://vimeo.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://vimeo.com/privacy
          </a>
          .
        </p>
        <h3>Google Web Fonts</h3>{" "}
        <p>
          Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so
          genannte Web Fonts, die von Google bereitgestellt werden. Beim Aufruf
          einer Seite l&auml;dt Ihr Browser die ben&ouml;tigten Web Fonts in
          ihren Browsercache, um Texte und Schriftarten korrekt anzuzeigen.
        </p>{" "}
        <p>
          Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu
          den Servern von Google aufnehmen. Hierdurch erlangt Google Kenntnis
          dar&uuml;ber, dass &uuml;ber Ihre IP-Adresse diese Website aufgerufen
          wurde. Die Nutzung von Google WebFonts erfolgt auf Grundlage von Art.
          6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
          Interesse an der einheitlichen Darstellung des Schriftbildes auf
          seiner Website. Sofern eine entsprechende Einwilligung abgefragt wurde
          (z. B. eine Einwilligung zur Speicherung von Cookies), erfolgt die
          Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit.
          a DSGVO; die Einwilligung ist jederzeit widerrufbar.
        </p>{" "}
        <p>
          Wenn Ihr Browser Web Fonts nicht unterst&uuml;tzt, wird eine
          Standardschrift von Ihrem Computer genutzt.
        </p>{" "}
        <p>
          Weitere Informationen zu Google Web Fonts finden Sie unter{" "}
          <a
            href="https://developers.google.com/fonts/faq"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://developers.google.com/fonts/faq
          </a>{" "}
          und in der Datenschutzerkl&auml;rung von Google:{" "}
          <a
            href="https://policies.google.com/privacy?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://policies.google.com/privacy?hl=de
          </a>
          .
        </p>
        <h3>Google Maps</h3>{" "}
        <p>
          Diese Seite nutzt den Kartendienst Google Maps. Anbieter ist die
          Google Ireland Limited (&bdquo;Google&ldquo;), Gordon House, Barrow
          Street, Dublin 4, Irland.
        </p>{" "}
        <p>
          Zur Nutzung der Funktionen von Google Maps ist es notwendig, Ihre
          IP-Adresse zu speichern. Diese Informationen werden in der Regel an
          einen Server von Google in den USA &uuml;bertragen und dort
          gespeichert. Der Anbieter dieser Seite hat keinen Einfluss auf diese
          Daten&uuml;bertragung.
        </p>{" "}
        <p>
          Die Nutzung von Google Maps erfolgt im Interesse einer ansprechenden
          Darstellung unserer Online-Angebote und an einer leichten
          Auffindbarkeit der von uns auf der Website angegebenen Orte. Dies
          stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f
          DSGVO dar. Sofern eine entsprechende Einwilligung abgefragt wurde,
          erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6
          Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.
        </p>{" "}
        <p>
          Die Daten&uuml;bertragung in die USA wird auf die
          Standardvertragsklauseln der EU-Kommission gest&uuml;tzt. Details
          finden Sie hier:{" "}
          <a
            href="https://privacy.google.com/businesses/gdprcontrollerterms/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://privacy.google.com/businesses/gdprcontrollerterms/
          </a>{" "}
          und{" "}
          <a
            href="https://privacy.google.com/businesses/gdprcontrollerterms/sccs/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://privacy.google.com/businesses/gdprcontrollerterms/sccs/
          </a>
          .
        </p>{" "}
        <p>
          Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der
          Datenschutzerkl&auml;rung von Google:{" "}
          <a
            href="https://policies.google.com/privacy?hl=de"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://policies.google.com/privacy?hl=de
          </a>
          .
        </p>
        <h3>Google Analytics</h3>
        <p>
          Diese Website benutzt Google Analytics, einen Webanalysedienst der
          Google Inc. ("Google"). Google Analytics verwendet sog. "Cookies",
          Textdateien, die auf Ihrem Computer gespeichert werden und die eine
          Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den
          Cookie erzeugten Informationen über Ihre Benutzung dieser Website
          werden in der Regel an einen Server von Google in den USA übertragen
          und dort gespeichert. Im Falle der Aktivierung der IP-Anonymisierung
          auf dieser Website, wird Ihre IP-Adresse von Google jedoch innerhalb
          von Mitgliedstaaten der Europäischen Union oder in anderen
          Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum
          zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an
          einen Server von Google in den USA übertragen und dort gekürzt. Im
          Auftrag des Betreibers dieser Website wird Google diese Informationen
          benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die
          Websiteaktivitäten zusammenzustellen und um weitere mit der
          Websitenutzung und der Internetnutzung verbundene Dienstleistungen
          gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google
          Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit
          anderen Daten von Google zusammengeführt. Sie können die Speicherung
          der Cookies durch eine entsprechende Einstellung Ihrer
          Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass
          Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser
          Website vollumfänglich werden nutzen können. Sie können darüber hinaus
          die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der
          Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die
          Verarbeitung dieser Daten durch Google verhindern, indem sie das unter
          dem folgenden Link verfügbare Browser-Plugin herunterladen und
          installieren: Browser-Plugin
          (http://tools.google.com/dlpage/gaoptout?hl=de). Die Speicherung von
          Google-Analytics-Cookies erfolgt auf Grundlage von Art. 6 Abs. 1 lit.
          f DSGVO. Die Speicherung erfolgt zeitlich unbegrenzt, sofern Sie nicht
          von Ihren Opt-out-Möglichkeiten Gebrauch machen.
        </p>
        <p>
          Quelle:{" "}
          <a href="https://www.e-recht24.de">https://www.e-recht24.de</a>
        </p>
      </SectionHelper>
    </>
  );
}

export default Datenschutz;
