import React, { useEffect, useRef } from "react";
import videojs from "video.js";

const VideoPlayer = (props) => {
  const { sources } = props;
  const playerRef = useRef();

  useEffect(() => {
    const player = videojs(
      playerRef.current,
      { autoplay: true, muted: true, loop: true },
      () => {
        player.src(sources);
      }
    );

    return () => {
      player.dispose();
    };
  }, [sources]);
  return (
    <div data-vjs-player>
      <video
        ref={playerRef}
        className="video-js vjs-default-skin vjs-fill"
        playsInline
        style={{
          position: "relative",
          overflow: "hidden",
          maxHeight: "500px",
          zIndex: 0,
          objectFit: "cover",
        }}
        height="50%"
        width="100%"
      />
    </div>
  );
};

export default VideoPlayer;
