// @ts-nocheck
import React from 'react'
import { Helmet } from 'react-helmet'

export interface SEOHeaderInterface {
  title: string
  description?: string
  keywords?: string
  author?: string
  publisher?: string
  revisitAfter?: string
  type?: string
  image?: string
  site?: string
  twitterCard?: string
  creator?: string
}
const SEOHeader = ({
  title,
  description = '',
  keywords = '',
  author = '',
  publisher = '',
  revisitAfter = '7 days',
  type = 'website',
  image = 'basic.jpg',
  site = '',
  twitterCard = 'summary_large_image',
  creator = ''
}: SEOHeaderInterface) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="author" content={author} />
      <meta name="publisher" content={publisher} />
      <meta name="revisit-after" content={revisitAfter} />

      {/* Facebook */}
      <meta name="og:type" content={type} />
      <meta name="og:title" content={title} />
      <meta name="og:description" content={description} />
      <meta name="og:url" content={window.location.href} />
      <meta
        name="og:image"
        content={`${process.env.REACT_APP_BASE_URL}/${image}`}
      />
      <meta name="og:image:width" content="1200" />
      <meta name="og:image:height" content="630" />
      <meta name="og:site_name" content={site} />

      {/* Twitter */}
      <meta name="twitter:card" content={twitterCard} />
      <meta name="twitter:site" content={site} />
      <meta name="twitter:creator" content={creator} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta
        name="twitter:image"
        content={`${process.env.REACT_APP_BASE_URL}/${image}`}
      />

      {/* Google */}
      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={description} />
      <meta
        itemProp="image"
        content={`${process.env.REACT_APP_BASE_URL}/${image}`}
      />
    </Helmet>
  )
}

export default SEOHeader
