import SEOHeader from "../components/SEOHeader";
import Content from "../i18n/de";
import styled from "styled-components";
import SectionHelper from "../components/SectionHelper";

const AboutContainer = styled.section`
  .text-box {
    padding: 10px 0 0;
    h1 {
      font-size: 80px;
      line-height: 1;
      margin: 0 0 10px 0;
    }
    h2 {
      font-size: 36px;
      line-height: 1;
      margin: 0 0 60px;
    }
  }
  @media (max-width: 768px) {
    .text-box {
      padding: 10px 0 0;
      h1 {
        font-size: 36px;
      }
      h2 {
        font-size: 24px;
        margin: 10px 0 20px;
      }
    }
  }
`;

function About() {
  return (
    <>
      <SEOHeader {...Content.meta.about} />
      <SectionHelper>
        <AboutContainer>
          <div class="container">
            <div class="columns">
              <div class="column is-offset-2 is-4">
                <img src="/images/about.jpg" alt="" />
              </div>
              <div class="column is-offset-1 is-3">
                <div class="text-box">
                  <p style={{ color: "#000" }}>
                    {" "}
                    Kunst und Multimedia LMU München <br /> Projektartbeit
                  </p>
                  <p style={{ color: "#000" }}>
                    Contact design@melike-mesin.de{" "}
                  </p>
                  <br />

                  <p>
                    Körper. Minimalismus. Frau. Natur.
                    <br /> Merkmale, die ineinander fließen und sich in Form,
                    Material, Struktur und Farbe als Interior Design
                    wiedergeben. Der Bruch zwischen geometrischen und
                    organischen Formen, dient dabei als Alleinstellungsmerkmal
                    der Ästhetik.
                    <br /> <br />
                    Interior Elemente.
                    <br /> Minimalistische Gemälde, Skulpturen und Kleinmöbel,
                    die mit weichen Linien auf harte Kanten treffen und ein
                    elegantes Statement bilden. Durch helle Farben und
                    raffinierten Design wird jede Ecke aufgewertet, ohne dabei
                    Raum zunehmen. Jedoch geht es hierbei nicht nur um die
                    ästhetische Aufwertung der Umgebung, sondern auch um echte
                    Botschaften.
                    <br /> <br />
                    Die Botschaft.
                    <br /> Frauen werden in der aktuellen, ‚modernen‘ Zeit,
                    immer noch in, von der Gesellschaft vorgegebenen,
                    Eigenschaften, reduziert. Ihre diversen Fähigkeiten werden
                    in Schubladen kategorisiert.
                    <br /> <br />
                    Besonders in der Arbeitswelt, wird die erfolgreiche Position
                    / Aufstieg, davon abhängig gemacht, welchen Aspekt sie ihrer
                    weiblichen Natur, ablegt oder benutzt. Dabei werden ihre
                    facettenreiche Fähigkeiten, die sich nicht in männlich oder
                    weiblich einteilen lassen, ignoriert. Die Interior Elemente
                    verkörpern die Frau, die den gesellschaftlichen Widerspruch
                    zwischen dem harten und den fragilen Linien bricht und
                    harmonisch tagtäglich miteinander verbindet.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </AboutContainer>
      </SectionHelper>
    </>
  );
}

export default About;
